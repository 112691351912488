.modal {
    position: absolute;
    top: 2%;
    right: 3%;
    bottom: 5%;
    margin: 0;
    padding-bottom: 20px;
    width: 450px;
    border-radius: 33px;
}

.ReactModal__Overlay--after-open {
    background-color: rgba(29, 46, 38, 0.51) !important;
    z-index: 999;
}

.modalTablet {
    width: 100vw;
    height: 100%;
    border-radius: 0;
    margin-bottom: 0;
    margin-top: 0;
    position: static;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.bottomModal {
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
    position: absolute;
    width: 450px;
    bottom: 5%;
    top: auto;
}

.notification {
    position: absolute;
    top: 33%;
    left: 40%;
    width: 400px;
}
